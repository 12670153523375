<template>
  <div
    :class="[
      'verification-code',
      {
        'verification-code--error': !!errMsg
      }
    ]"
  >
    <page-title>
      Enter Verification Code
    </page-title>
    <div class="verification-code__main">
      <p class="verification-code__desc">
        We sent a four-digit verification code to your email, please enter the code below.
      </p>
      <div class="verification-code__code">
        <input
          type="text"
          class="verification-code__code-item"
          v-for="(code, i) in codeList"
          ref="inputRef"
          :value="code"
          :key="i"
          @input="input(i, $event)"
          @keyup.delete="deleteVal(i)"
        />
        <span v-if="!!errMsg" class="verification-code__error-tip">
          Wrong verification code! Please click“Resend code”
        </span>
      </div>
      <y-button
        :disabled="disabled"
        :loading="
          $store.state.loading['/overseas/user/register'] ||
          $store.state.loading['/overseas/user/verificationCode']
        "
        class="verification-code__submit"
        type="primary"
        @click="submit"
      >
        SUBMIT
      </y-button>
    </div>
    <p class="verification-code__foot">
      <span class="verification-code__foot-back" @click="back">
        Back
      </span>
      <y-button
        @click="resend"
        type="text"
        :loading="$store.state.loading['/overseas/user/requestVerify']"
      >
        Resend code
      </y-button>
    </p>
  </div>
</template>

<script>
import PageTitle from './page-title'

export default {
  components: {
    PageTitle
  },
  data() {
    return {
      codeList: ['', '', '', ''],
      errMsg: false
    }
  },
  computed: {
    disabled() {
      return this.codeList.some((item) => !item)
    }
  },
  methods: {
    // 提交数据
    submit() {
      if (this.verify()) {
        this.$emit('submit', this.codeList)
        return
      }
      this.errMsg = true
    },
    verify() {
      if (this.codeList.some((item) => !item)) {
        return false
      }
      return true
    },
    // 返回
    back() {
      this.$emit('back')
    },
    // 重发信息
    async resend() {
      this.$emit('resend')
    },
    // input 输入
    input(i, e) {
      const inputRefs = this.$refs.inputRef
      const value = e.data
      if (i < inputRefs.length - 1 && value !== null) {
        inputRefs[i + 1].focus()
      }
      this.codeList.splice(i, 1, value)
      if (this.errMsg) {
        this.errMsg = false
      }
    },
    // 键盘删除
    deleteVal(i) {
      const inputRefs = this.$refs.inputRef
      if (i > 0) {
        inputRefs[i - 1].focus()
      }
    },
    // 返回当前组件数据
    getData() {
      return this.codeList
    },
    // 设置错误
    setError(errMsg) {
      this.errMsg = errMsg
    }
  }
}
</script>

<style lang="scss" scoped>
.verification-code {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.verification-code__main {
  margin-top: 40px;
  flex: 1;
}
.verification-code__desc {
  margin-bottom: 30px;
  line-height: 1.5;
}
.verification-code__code {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  position: relative;
}
.verification-code__code-item {
  outline: none;
  width: 64px;
  height: 64px;
  line-height: 62px;
  text-align: center;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: 600;
  font-size: 36px;
}
.verification-code__error-tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 84px;
  color: $--color-primary;
  white-space: nowrap;
}
.verification-code .verification-code__submit {
  width: 100%;
  line-height: 24px;
}
.verification-code__foot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.verification-code__foot-back {
  cursor: pointer;
  font-size: 14px;
}
.verification-code--error {
  .verification-code__code-item {
    border-color: $--color-primary;
  }
}
</style>
