<template>
  <div class="page-register">
    <!-- 注册界面 -->
    <register
      ref="registerRef"
      v-show="active === 'register'"
      @submit="registerSubmit"
    ></register>
    <!-- /注册界面 -->

    <!-- 验证码界面 -->
    <verification-code
      ref="verificationCodeRef"
      v-show="active === 'verification-code'"
      @submit="verificationCodeSubmit"
      @resend="verificationCodeResend"
      @back="verificationCodeBack"
    ></verification-code>
    <!-- /验证码界面 -->
  </div>
</template>

<script>
import VerificationCode from '../components/verification-code.vue'
import Register from './_register.vue'

export default {
  components: {
    VerificationCode,
    Register
  },
  data() {
    return {
      active: 'register'
    }
  },
  methods: {
    // 注册界面提交数据, 切换成校验码界面
    registerSubmit() {
      this.active = 'verification-code'
    },
    // 校验码和注册信息提交注册
    async verificationCodeSubmit() {
      // 获取注册界面的数据: email, password, username
      const registerData = this.$refs.registerRef.getData()
      // const registerData = {
      //   username: 'lee',
      //   email: 'yunxi@qq.com',
      //   password: '1q1q1q'
      // }
      // 获取校验码界面的数据: codeList
      const codeList = this.$refs.verificationCodeRef.getData()
      const res = await this.$serve.user.register({
        data: {
          ...registerData,
          code: codeList.join('')
        },
        hideError: true
      })
      if (res === this.$serve.FAIL) {
        this.$refs.verificationCodeRef.setError(true)
        return
      }
      this.$message.success('Create success')
      this.$router.push('/user/login')
    },
    // 重发获取校验码
    verificationCodeResend() {
      this.$refs.registerRef.verifyRegisterInfo({
        hideloading: false
      })
    },
    // 校验码返回点击跳转至注册界面
    verificationCodeBack() {
      this.active = 'register'
    }
  }
}
</script>

<style lang="scss" scoped>
.page-register {
  height: 100%;
  ::v-deep .y-pro-form {
    .el-form-item {
      position: relative;
      &:not(:last-child) {
        margin-bottom: 48px;
      }
      &:last-child {
        margin-bottom: 24px;
      }
      .el-form-item__label {
        position: absolute;
        left: 0;
        top: 50%;
        line-height: 36px;
        margin-top: -18px;
        z-index: 1;
        pointer-events: none;
        transition: transform 0.2s ease, font-size 0.2s ease;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.4);
      }
      .el-form-item__content {
        line-height: 36px;
      }
      .el-input__inner {
        padding: 0;
        height: 36px;
        line-height: 36px;
      }
    }
    .el-form-item.y-pro-form__item--vertical {
      .el-form-item__label {
        transform: translateY(-28px);
      }
    }
    input {
      border-left: none;
      border-right: none;
      border-top: none;
      border-radius: 0;
    }
    .el-form-item__error {
      width: 100%;
      text-align: right;
    }
  }
  ::v-deep button[type='primary'] {
    height: 48px;
    font-size: 16px;
  }
}
</style>
