<template>
  <div class="_register">
    <page-title>Create your account</page-title>
    <div class="_register__main">
      <y-pro-form
        ref="formRef"
        :model="model"
        :fields="fields"
        :rules="rules"
        :hide-required-asterisk="true"
        :submitter="{
          submitText: 'SUBMIT',
          submitButtonProps: {
            loading: $store.state.loading['/overseas/user/verifyRegisterInfo'],
            style: {
              marginTop: '40px',
              width: '100%'
            }
          },
          resetButtonProps: {
            style: {
              display: 'none'
            }
          }
        }"
        @submit="submit"
      ></y-pro-form>
      <p class="_register__desc">
        Already have an account?
        <router-link class="c-primary" to="/user/login">Log In</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { nicknameReg, emailReg, numAndWordReg } from '@utils/regex'
import PageTitle from '../components/page-title'

export default {
  components: {
    PageTitle
  },
  data() {
    return {
      model: {
        nickname: '',
        email: '',
        password: ''
      },
      fields: [
        // 表单用户名配置
        {
          type: 'input',
          formItemProps: {
            label: 'Nickname',
            prop: 'nickname',
            class: ''
          },
          attributes: {
            autocomplete: 'new-password',
            placeholder: ''
          },
          events: {
            input: (v) => {
              if (v) {
                this.fields[0].formItemProps.class =
                  'y-pro-form__item--vertical'
              }
            },
            focus: () => {
              this.fields[0].formItemProps.class = 'y-pro-form__item--vertical'
            },
            blur: () => {
              if (!this.model.nickname) {
                this.fields[0].formItemProps.class = ''
              }
            }
          }
        },
        // 表单密码配置
        {
          type: 'input',
          formItemProps: {
            label: '*Email Address',
            prop: 'email',
            class: ''
          },
          attributes: {
            autocomplete: 'new-password',
            placeholder: ''
          },
          events: {
            input: (v) => {
              if (v) {
                this.fields[1].formItemProps.class =
                  'y-pro-form__item--vertical'
              }
            },
            focus: () => {
              this.fields[1].formItemProps.class = 'y-pro-form__item--vertical'
            },
            blur: () => {
              if (!this.model.email) {
                this.fields[1].formItemProps.class = ''
              }
            }
          }
        },
        // 表单密码配置
        {
          type: 'password',
          formItemProps: {
            label: '*Password',
            prop: 'password',
            class: ''
          },
          attributes: {
            autocomplete: 'new-password',
            placeholder: ''
          },
          events: {
            input: (v) => {
              if (v) {
                this.fields[2].formItemProps.class =
                  'y-pro-form__item--vertical'
              }
            },
            focus: () => {
              this.fields[2].formItemProps.class = 'y-pro-form__item--vertical'
            },
            blur: () => {
              if (!this.model.password) {
                this.fields[2].formItemProps.class = ''
              }
            }
          }
        }
      ],
      // 表单校验规则配置
      rules: {
        // 表单用户名校验规则配置
        nickname: [
          {
            validator: (_, value, callback) => {
              if (value.trim().length > 20 || !nicknameReg.test(value)) {
                callback(
                  new Error(
                    'The length of the nickname cannot exceed 20 letters and cannot contain other symbols'
                  )
                )
                return
              }
              callback()
            }
          }
        ],
        // 表单邮箱校验规则配置
        email: [
          {
            required: true,
            message: 'Email address is required',
            trigger: 'change'
          },
          {
            validator: async (_, value, callback) => {
              if (!value.trim()) {
                callback(new Error('Email address is required'))
                return
              }
              if (!emailReg.test(value)) {
                callback(new Error('enter a vaild address'))
                return
              }
              const res = await this.emailValid(value)
              if (res === true) {
                callback(new Error('This email is already in use'))
                return
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        // 表单密码校验规则配置
        password: [
          {
            required: true,
            message: 'Password is required',
            trigger: 'change'
          },
          {
            validator: (_, value, callback) => {
              if (!value.trim()) {
                callback(new Error('Password is required'))
                return
              }
              if (value.length < 6) {
                callback(
                  new Error('The password should has at least 6 characters')
                )
                return
              }
              if (value.length > 20) {
                callback(new Error('Password cannot exceed 20 characters long'))
                return
              }
              if (!numAndWordReg.test(value)) {
                callback(
                  new Error(
                    'The password should contain at least a letter and a number'
                  )
                )
                return
              }
              callback()
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    // 校验邮箱
    async emailValid(email) {
      const res = await this.$serve.user.emailValid({
        params: {
          email
        }
      })
      if (res === this.$serve.FAIL) {
        return this.$serve.FAIL
      }
      return res.isRegister
    },
    // 注册邮箱和密码, 获取校验码
    submit() {
      this.verifyRegisterInfo({ hideloading: true })
    },
    async verifyRegisterInfo({ hideloading }) {
      const res = await this.$serve.user.verifyRegisterInfo({
        data: {
          email: this.model.email,
          password: this.model.password
        },
        hideloading
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$emit('submit')
    },
    getData() {
      return this.model
    }
  }
}
</script>

<style lang="scss" scoped>
._register__main {
  margin-top: 40px;
}
.y-pro-form {
  ::v-deep input {
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
  }
}
._register__desc {
  line-height: 20px;
  margin-top: 30px;
  text-align: center;
  font-size: 14px;
}
._register {
  ::v-deep .submitter-btns {
    .el-button {
      margin-top: 16px !important;
    }
  }
}
</style>
