var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_register"},[_c('page-title',[_vm._v("Create your account")]),_c('div',{staticClass:"_register__main"},[_c('y-pro-form',{ref:"formRef",attrs:{"model":_vm.model,"fields":_vm.fields,"rules":_vm.rules,"hide-required-asterisk":true,"submitter":{
        submitText: 'SUBMIT',
        submitButtonProps: {
          loading: _vm.$store.state.loading['/overseas/user/verifyRegisterInfo'],
          style: {
            marginTop: '40px',
            width: '100%'
          }
        },
        resetButtonProps: {
          style: {
            display: 'none'
          }
        }
      }},on:{"submit":_vm.submit}}),_c('p',{staticClass:"_register__desc"},[_vm._v(" Already have an account? "),_c('router-link',{staticClass:"c-primary",attrs:{"to":"/user/login"}},[_vm._v("Log In")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }